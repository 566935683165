import Drawings1 from '../../assets/drawings/1-min.png';
import Drawings2 from '../../assets/drawings/2-min.png';
import Drawings3 from '../../assets/drawings/3-min.png';
import Drawings4 from '../../assets/drawings/4-min.png';
import PreviousNextProject from '../../shared/components/PreviousNextProject/PreviousNextProject';
import ProjectTopSection from '../../shared/components/ProjectTopSection/ProjectTopSection';
import styles from './Drawings.module.scss';

const DrawingsMobile = () => {
    return (
        <div style={{
            marginTop: '11rem',
        }}>
            <ProjectTopSection title='Drawings' subTitle='&Paintings'></ProjectTopSection>
            <img style={{ marginTop: '4rem' }} className={styles.pageImage} src={Drawings1} alt="drawings" />
            <div className={styles.imageTitle}>Ink&Paint on cotton</div>
            <img style={{ marginTop: '2rem' }} className={styles.pageImage} src={Drawings2} alt="drawings" />
            <div className={styles.imageTitle}>Gravure printing</div>
            <img style={{ marginTop: '2rem' }} className={styles.pageImage} src={Drawings3} alt="drawings" />
            <div className={styles.imageTitle}>Loose sketches</div>
            <img style={{ marginBottom: '9rem' }} className={styles.pageImage} src={Drawings4} alt="drawings" />
            <PreviousNextProject previousTo='/selfieroom' previousToTitle='Selfieroom' nextTo='/cd-cover' nextToTitle='Cd cover' />
        </div>
    )
}

export default DrawingsMobile;