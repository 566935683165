import { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from './ProjectTail.module.scss';

interface Props {
    image: any;
    title: string;
    link: string;
    subtitle?: string;
    reversedDesktop?: boolean;
}

const ProjectTail: FC<Props> = ({ image, title, subtitle, link, reversedDesktop }) => {
    let projectDetailsCompound = `${styles.projectDetails}`;
    if (reversedDesktop) {
        projectDetailsCompound += ` ${styles.reversedDesktop}`;
    }

    return (
        <Link className={styles.projectTail} to={link}>
            <div className={projectDetailsCompound}>
                <div className={styles.projectDetailsChild}>
                    <div className={styles.projectDetailsTextBlock}>
                        <h3 className={styles.projectDetailsHeader}>{title}</h3>
                        <span className={styles.projectDetailsSubtitle}>{subtitle}</span>
                    </div>
                </div>
                <div className={styles.projectDetailsChild}>
                    <img src={image} alt="compass showcase" />
                </div>
            </div>
        </Link>
    )
}

export default ProjectTail