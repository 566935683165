import Illustrations1 from '../../assets/illustrations/illustrationsmobile-min.png';
import PreviousNextProject from '../../shared/components/PreviousNextProject/PreviousNextProject';
import ProjectTopSection from '../../shared/components/ProjectTopSection/ProjectTopSection';
import styles from './Illustrations.module.scss';

const IllustrationsMobile = () => {
    return (
        <div style={{
            marginTop: '11rem',
        }}>
            <ProjectTopSection title='Illustrations'></ProjectTopSection>
            <img style={{ marginTop: '6rem' }} className={styles.pageImage} src={Illustrations1} alt="Illustrations" />
            <PreviousNextProject previousTo='/cd-cover' previousToTitle='CD cover' nextTo='/compass-mobile' nextToTitle='Compass mobile' />
        </div>
    )
}

export default IllustrationsMobile;