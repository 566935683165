import { FC, ReactNode } from 'react';
import styles from './ProjectTopSection.module.scss';

interface Props {
    title: string;
    subTitle?: string;
    children?: ReactNode;
}

const ProjectTopSection: FC<Props> = ({
    title,
    subTitle,
    children
}) => {
    return (
        <>
            {/* takie same odległości poza tym kompoenntem  */}
            <h2>{title}</h2>
            <h4 className="weight-normal">{subTitle}</h4>
            {
                children
                    ? <p className={`${styles.mainParagraph} body`}>{children}</p>
                    : null
            }
        </>

    )
}

export default ProjectTopSection