import { Routes, Route, Outlet } from "react-router-dom";
import About from './pages/About/About';
import Compass from './pages/Compass/Compass';
import LinguaFinder from './pages/Linguafinder/LinguaFinder';
import MainPage from './pages/MainPage/MainPage';
import Header from './layout/Header';
import Footer from './layout/Footer';
import ScrollToTop from './shared/components/ScrollToTop';
import Selfieroom from './pages/Selfieroom/Selfieroom';
import Drawings from './pages/Drawings/Drawings';
import Illustrations from './pages/Illustrations/Illustrations';
import styles from './App.module.scss';
import CdCover from './pages/CdCover/CdCover';
import CompassWeb from './pages/CompassWeb/CompassWeb';

export default function App() {
    return (
        <div>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<MainPage />} />
                    <Route path="about" element={<About />} />

                    <Route path="compass-mobile" element={<Compass />} />
                    <Route path="compass-desktop" element={<CompassWeb />} />
                    <Route path="linguafinder" element={<LinguaFinder />} />
                    <Route path="selfieroom" element={<Selfieroom />} />
                    <Route path="drawings" element={<Drawings />} />
                    <Route path="cd-cover" element={<CdCover />} />
                    <Route path="illustrations" element={<Illustrations />} />
                </Route>
            </Routes>
        </div>
    );
}

function Layout() {
    return (
        <div className='container'>
            <Header />
            <div className={styles.responsiveContainer}>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}