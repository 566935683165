import selfieroom from '../../assets/selfieroom/selfieroom_desktop.png';
import PreviousNextProject from '../../shared/components/PreviousNextProject/PreviousNextProject';
import ProjectTopSection from '../../shared/components/ProjectTopSection/ProjectTopSection';
import styles from './Selfieroom.module.scss';

const SelfieroomDesktop = () => {
    return (
        <div style={{
            marginTop: '11rem',
        }}>
            <ProjectTopSection title='Selfieroom' subTitle='UI'>
                Selfieroom is Polish fashion e-commerce shop for women. The fashion industry has gone through a number of changes in recent years. Due to the coronavirus pandemic, a large part of sales has moved online. This situation has increased competition in this field. In order to maintain their position on the market, companies had to adapt their stores to new customer demand. Just like many other brands, Selfieroom has also undergone through some changes. Their early sales policy put quantity before quality. In the new system, quality is a new priority and design has to follow that rule as well.
                <br />
                <br />
                New form of design is characterized by a clean spaces and simple forms. This style allows to display the brand's products at ease. Round shapes of category section is refering to the layout of instagram. That form is familiar to users and is associated with the most fashionable trends.
            </ProjectTopSection>
            <img style={{ marginBottom: '9rem' }} className={styles.pageImage} src={selfieroom} alt="Mobile showcase" />
            <a className='body' style={{ marginBottom: '12rem', display: 'inline-block' }} href='https://www.selfieroom.pl/'>
                <h4 style={{ fontWeight: 400, textDecoration: 'underline' }}>
                    Link to <h4 style={{ fontWeight: 600, display: 'inline' }}>Selfieroom</h4>
                </h4>
            </a>
            <PreviousNextProject previousTo='/linguafinder' previousToTitle='LinguaFinder' nextTo='/drawings' nextToTitle='Drawings & Paintings' />
        </div>
    )
}

export default SelfieroomDesktop