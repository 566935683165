import styles from './LinguaFinder.module.scss';
import loader from '../../assets/in-progress.gif';
import PreviousNextProject from '../../shared/components/PreviousNextProject/PreviousNextProject';

const LinguaFinder = () => {
    return (
        <>
            <div className={styles.mainBox}>
                <div className={styles.loaderWrapper}>
                    <img className={styles.loader} src={loader} alt="in progress" />
                </div>
                <div className={styles.loaderText}>
                    <h2 className='d-none d-sm-inline'>In progress</h2>
                    <h3 className='d-sm-none'>In progress</h3>
                </div>

            </div>
            <PreviousNextProject previousTo='/compass-desktop' previousToTitle='Compass desktop' nextTo='/selfieroom' nextToTitle='Selfieroom' />
        </>
    )
}

export default LinguaFinder