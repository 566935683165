import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../../assets/arrow.svg';
import styles from './PreviousNextProject.module.scss';

interface Props {
    nextTo: string;
    nextToTitle: string;
    previousTo: string;
    previousToTitle: string;
}

const PreviousNextProject: FC<Props> = ({ nextTo, nextToTitle, previousTo, previousToTitle }) => {
    return (
        <div>
            <div className={styles.wrapper}>
                <Link to={previousTo}>
                    <div className={`${styles.navigationWrapper} d-none d-sm-flex`}>
                        <div>
                            <Arrow className={styles.leftArrow} />
                        </div>
                        <div className={styles.arrowTitle}>
                            <h4>
                                Previous project
                            </h4>
                            <span className="secondary-color">
                                {previousToTitle}
                            </span>
                        </div>
                    </div>
                </Link>

                <Link to={nextTo}>
                    <div className={styles.navigationWrapper}>
                        <div className={styles.arrowTitle} style={{ textAlign: 'right' }}>
                            <h4>
                                Next project
                            </h4>
                            <span className="secondary-color">
                                {nextToTitle}
                            </span>
                        </div>
                        <div>
                            <Arrow className={styles.rightArrow} />
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default PreviousNextProject;