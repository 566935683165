import Drawings1 from '../../assets/drawings/1.png';
import Drawings2 from '../../assets/drawings/2.png';
import PreviousNextProject from '../../shared/components/PreviousNextProject/PreviousNextProject';
import ProjectTopSection from '../../shared/components/ProjectTopSection/ProjectTopSection';
import styles from './Drawings.module.scss';

const DrawingsDesktop = () => {
    return (
        <div style={{
            marginTop: '11rem',
        }}>
            <ProjectTopSection title='Drawings' subTitle='&Paintings'></ProjectTopSection>
            <img style={{ marginTop: '6rem' }} className={styles.pageImage} src={Drawings1} alt="drawings" />
            <img className={styles.pageImage} src={Drawings2} alt="drawings" />
            <PreviousNextProject previousTo='/selfieroom' previousToTitle='Selfieroom' nextTo='/cd-cover' nextToTitle='Cd cover' />
        </div>
    )
}

export default DrawingsDesktop;