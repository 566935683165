import CdCover1 from '../../assets/cdcover/mobile1-min.png';
import CdCover2 from '../../assets/cdcover/mobile2-min.png';
import CdCover3 from '../../assets/cdcover/mobile3-min.png';
import CdCover4 from '../../assets/cdcover/mobile4-min.png';
import PreviousNextProject from '../../shared/components/PreviousNextProject/PreviousNextProject';
import ProjectTopSection from '../../shared/components/ProjectTopSection/ProjectTopSection';
import styles from './CdCover.module.scss';

const CdCoverMobile = () => {
    return (
        <div style={{
            marginTop: '11rem',
        }}>
            <ProjectTopSection title='Sekluzja' subTitle='CD Cover'>
                Sekluzja is the neologizm based on word “secluded” and transformed into polish language. Band is represented by 3 young artists living in Cracow. Jazz noir is a genre that best describes Sekluzja. Jazz improvisation, minimal ambient sounds and musical pauses or vibrations of sounds is all part of this musical experiment. The structure is loose, sometimes unplanned, creating nostalgic, a little heavy and dark vibe.
            </ProjectTopSection>
            <img style={{ marginBottom: '0rem' }} className={styles.pageImage} src={CdCover1} alt="Sekluzja" />
            <h3 className="mt-5 mb-5">
                “They are never born empty handed”
            </h3>
            <p className="body mb-5">
                The priority with this cover was to maintain a dark, hazy atmosphere of mystery and seclusion. The colors are maintained in the canon of the jazz noir genre. When coming up with the theme of the photo shoot, I thought of the band members as "aliens" born in space, never empty-handed. Figures are embedded in a dark misty mass referring to the void in outer space.
            </p>
            <img style={{ marginBottom: '0rem' }} className={styles.pageImage} src={CdCover2} alt="Sekluzja" />
            <img style={{ marginBottom: '0rem' }} className={styles.pageImage} src={CdCover3} alt="Sekluzja" />
            <h3 className="mt-5 mb-5">
                “The factory may be old but the steelworks is new”
            </h3>
            <p className="body mb-5">
                When I was listening to this album for the first time, Krzysztof, the founder of the band, revealed to me the name of it. Ambient, heavy sounds combined with the word “Factory” immediately brought to my mind the vision of post-apocalyptic industrial space station embedded in the void. Mysterious numbers visible on the back of the plate emphasize technological secret of this almost inhuman machine.
            </p>
            <img style={{ marginBottom: '9rem' }} className={styles.pageImage} src={CdCover4} alt="Sekluzja" />
            <a className='body' style={{ marginBottom: '12rem', display: 'inline-block' }} href='/'>
                <h4 style={{ fontWeight: 400, textDecoration: 'underline' }}>
                    Listen to <h4 style={{ fontWeight: 600, display: 'inline' }}>Sekluzja</h4>
                </h4>
            </a>
            <PreviousNextProject previousTo='/drawings' previousToTitle='Drawings & Paintings' nextTo='/illustrations' nextToTitle='Illustrations' />
        </div>
    )
}

export default CdCoverMobile