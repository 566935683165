import { Link } from 'react-router-dom';
import compass1 from '../../assets/compass-mob/1.png';
import compass2 from '../../assets/compass-mob/2.gif';
import compass3 from '../../assets/compass-mob/3.png';
import compass4 from '../../assets/compass-mob/4.gif';
import compass5 from '../../assets/compass-mob/5.png';
import compass6 from '../../assets/compass-mob/6.gif';
import compass7 from '../../assets/compass-mob/7.png';
import compass8 from '../../assets/compass-mob/8.gif';
import compass9 from '../../assets/compass-mob/9.png';
import PreviousNextProject from '../../shared/components/PreviousNextProject/PreviousNextProject';
import styles from './Compass.module.scss';

const CompassMobile = () => {

    // todo: add next project
    return (
        <div>
            <img className={styles.pageImage} src={compass1} alt="Compass main" />
            <img className={styles.pageImage} src={compass2} alt="Mobile showcase" />
            <img className={styles.pageImage} src={compass3} alt="lol" />
            <img className={styles.pageImage} src={compass4} alt="lol" />
            <img className={styles.pageImage} src={compass5} alt="lol" />
            <img className={styles.pageImage} src={compass6} alt="lol" />
            <img className={styles.pageImage} src={compass7} alt="lol" />
            <img className={styles.pageImage} src={compass8} alt="lol" />
            <img className={styles.pageImage} src={compass9} alt="lol" />
            <a className='body' style={{ marginTop: '12rem', display: 'inline-block' }} href='https://www.figma.com/file/qBM8IZqzE1cOsv9e4UZhfG/Compass-mobile-web?node-id=0%3A1&t=c89miCGWjPIdO0h2-1'>
                <h4 style={{ fontWeight: 400, textDecoration: 'underline' }}>
                    Project in <h4 style={{ fontWeight: 600, display: 'inline' }}>Figma</h4>
                </h4>
            </a>
            <br />
            <Link className='body mt-4' style={{ marginBottom: '12rem', display: 'inline-block' }} to='/compass-desktop'>
                <h4 style={{ fontWeight: 400, textDecoration: 'underline' }}>
                    Version for <h4 style={{ fontWeight: 600, display: 'inline' }}>desktop</h4>
                </h4>
            </Link>
            <PreviousNextProject previousTo='/illustrations' previousToTitle='Illustrations' nextTo='/compass-desktop' nextToTitle='Compass desktop' />
        </div>
    )
}

export default CompassMobile