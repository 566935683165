import styles from './About.module.scss';
import topPhoto from '../../assets/about/avatar.png';
import animationLoading from '../../assets/in-progress.gif';
import animation from '../../assets/about/animejno.gif';


const MainPage = () => {

    return (
        <div className={styles.aboutWrapper}>
            <div className={styles.topPhoto}>
                <img src={topPhoto} alt="Avatar" />
            </div>
            <h2 className={`${styles.jobTitle}`}><h2 className={styles.jobTitle} style={{ display: 'inline-block', color: '#9747ff' }}>UI</h2> Designer</h2>
            <h2 className={styles.subJobTitle}>& art enthusiast</h2>
            <div className={`body`}>
                Based in Cracow, Poland, specializing in creating <span className="body secondary-color">web</span> and <span className="body secondary-color">mobile design</span>.
                I am passionate about shaping visions in both real and digital world, keeping in mind that visually pleasing without practical sense doesn’t make sense at all.
            </div>
            <div className={styles.firstAnimation}>
                <img src={animationLoading} alt="animation" />
            </div>
            <span id="works"></span>
            <h3 className={styles.middleHeader}>
                I am always trying to look<br /> at project from the right point of view.
            </h3>
            <p className="body">
                With my over one year experience in web design I enjoyed working on:
            </p>
            <ul>
                <li className="body">an e-commerce project for cartographic publishing house</li>
                <li className="body">creating userflow and ui design for integration of third-party data provider in health care sector</li>
                <li className="body">graphics and contents for selfieroom polish fashion website</li>
            </ul>
            <p className={`${styles.workWith} body`}>
                <b className={`body`}>I work with:</b><br />Figma, Adobe XD, Adobe Photoshop, Adobe Illustrator, Adobe Indesign, Adobe After Effect, Google Analytics, Miro
            </p>
            <img className={styles.secondAnimation} src={animation} alt="animation" />
            <h3 className='fw-bold'>
                Let’s Roll
            </h3>
        </div>
    )
}

export default MainPage