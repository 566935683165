import { Link } from "react-router-dom";
import compass1 from "../../assets/compass/main-page-min.jpg";
import PreviousNextProject from "../../shared/components/PreviousNextProject/PreviousNextProject";
import styles from "./CompassWeb.module.scss";

const CompassWebMobile = () => {
  // todo: add next project
  return (
    <div>
      <img className={styles.pageImage} src={compass1} alt="Compass main" />
      <a
        className="body"
        style={{ marginTop: "12rem", display: "inline-block" }}
        href="https://www.figma.com/proto/rtTPNmSVHemLqCAfCSHUT1/COMPASS-desktop?page-id=210%3A821&node-id=441%3A3956&viewport=21%2C1265%2C0.25&scaling=min-zoom&starting-point-node-id=441%3A3956"
      >
        <h4 style={{ fontWeight: 400, textDecoration: "underline" }}>
          Project in <h4 style={{ fontWeight: 600, display: "inline" }}>Figma</h4>
        </h4>
      </a>
      <br />
      <Link className="body mt-4" style={{ marginBottom: "12rem", display: "inline-block" }} to="/compass-desktop">
        <h4 style={{ fontWeight: 400, textDecoration: "underline" }}>
          Version for <h4 style={{ fontWeight: 600, display: "inline" }}>desktop</h4>
        </h4>
      </Link>
      <PreviousNextProject
        previousTo="/compass-mobile"
        previousToTitle="Compass mobile"
        nextTo="/linguafinder"
        nextToTitle="Linguafinder"
      />
    </div>
  );
};

export default CompassWebMobile;
