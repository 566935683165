import { useEffect } from 'react';
import CompassWebDesktop from './CompassWebDesktop';
import CompassWebMobile from './CompassWebMobile';

const CompassWeb = () => {

    let isMobile = window.innerWidth < 400;

    useEffect(() => {
        window.addEventListener("resize", () => {
            // console.log('resized', window.innerWidth);
        });
    }, [])


    if (isMobile) {
        return <CompassWebMobile />
    } else {
        return <CompassWebDesktop />
    }
}

export default CompassWeb;