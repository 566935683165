import { Link, NavLink } from 'react-router-dom';
import styles from './Header.module.scss';
import logo from '../assets/logo.svg';

const Header = () => {
    return (
        <nav className={styles.header}>
            <Link to="/">
                <img className={styles.logo} src={logo} alt="Logo" />
            </Link>
            <div className={styles.nav}>
                <NavLink className={({ isActive }) =>
                    isActive ? `${styles.link} ${styles.active} pe-4` : `${styles.link} pe-4`
                } to="/">Works</NavLink>
                <NavLink className={({ isActive }) =>
                    isActive ? `${styles.link} ${styles.active}` : styles.link
                } to="/about">About</NavLink>
            </div>
        </nav>
    )
}

export default Header