import { useEffect } from 'react';
import CompassDesktop from './CompassDesktop';
import CompassMobile from './CompassMobile';

const Compass = () => {

    let isMobile = window.innerWidth < 400;

    useEffect(() => {
        window.addEventListener("resize", () => {
            // console.log('resized', window.innerWidth);
        });
    }, [])


    if (isMobile) {
        return <CompassMobile />
    } else {
        return <CompassDesktop />
    }
}

export default Compass;