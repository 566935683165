import styles from "./MainPage.module.scss";
import compassDesktop from "../../assets/tails/compass_desktop.jpg";
import compassMobile from "../../assets/tails/compass_mobile.png";
import linguafinder from "../../assets/tails/linguafinder.png";
import selfieroom from "../../assets/tails/selfieroom.png";
import drawings from "../../assets/tails/drawings.png";
import illustrations from "../../assets/tails/illustrations.png";
import cdcover from "../../assets/tails/cd_cover.png";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import ProjectTail from "./ProjectTail";

const MainPage = () => {
  const scroll = () => {
    const element = document.querySelector("#works");
    if (element) {
      element.scrollIntoView();
    }
  };

  return (
    <>
      <div style={{ marginTop: "5.5rem" }}>
        <h4 className={`${styles.firstText} slim`}>I'm Elizabeth</h4>
        <h1 className={`${styles.jobTitle} mb-4`}>
          <h1 className={styles.jobTitle} style={{ display: "inline-block", color: "#9747ff" }}>
            UI
          </h1>{" "}
          Designer
        </h1>
        <div className={`${styles.subText} d-sm-none`}>
          I am open to new challenges and I can’t wait to hear about your problems to solve!
        </div>
        <div className={`${styles.subText} d-none d-sm-block`}>
          I am open to new challenges and I can’t wait to hear
          <br />
          about your problems to solve!
        </div>
        <span id="works"></span>
        <span onClick={scroll} className={styles.arrow}>
          <Arrow fill="red" />
          <span className={styles.worksLink}>Works</span>
        </span>
      </div>

      <ProjectTail link="compass-mobile" image={compassMobile} title="UX/UI" subtitle="Compass mobile" reversedDesktop={true} />
      <hr />
      <ProjectTail link="compass-desktop" image={compassDesktop} title="UX/UI" subtitle="Compass desktop" reversedDesktop={true} />
      <hr />
      <ProjectTail link="linguafinder" image={linguafinder} title="UX/UI" subtitle="LinguaFinder" reversedDesktop={true} />
      <hr />
      <ProjectTail link="selfieroom" image={selfieroom} title="UI" subtitle="Selfieroom" reversedDesktop={true} />
      <hr />
      <ProjectTail link="drawings" image={drawings} title="Drawings" subtitle="&Paintings" reversedDesktop={true} />
      <hr />
      <ProjectTail link="cd-cover" image={cdcover} title="Cd cover" subtitle="Sekluzja" reversedDesktop={true} />
      <hr />
      <ProjectTail link="illustrations" image={illustrations} title="Illustrations" reversedDesktop={true} />
    </>
  );
};

export default MainPage;
