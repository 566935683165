import { useEffect } from 'react';
import { breakpoints } from '../../scss/breakpoints';
import DrawingsDesktop from './DrawingsDesktop';
import DrawingsMobile from './DrawingsMobile';

const Drawings = () => {

    let isMobile = window.innerWidth < breakpoints.sm;

    useEffect(() => {
        window.addEventListener("resize", () => {
            // console.log('resized', window.innerWidth);
        });
    }, [])


    if (isMobile) {
        return <DrawingsMobile />
    } else {
        return <DrawingsDesktop />
    }
}

export default Drawings;