import CdCover1 from '../../assets/cdcover/sekluzja1-min.png';
import CdCover2 from '../../assets/cdcover/sekluzja2-min.png';
import PreviousNextProject from '../../shared/components/PreviousNextProject/PreviousNextProject';
import ProjectTopSection from '../../shared/components/ProjectTopSection/ProjectTopSection';
import styles from './CdCover.module.scss';

const CdCoverDesktop = () => {
    return (
        //TODO: 90px above title and between subtitle and para
        <div style={{
            marginTop: '11rem',
        }}>
            <ProjectTopSection title='Sekluzja' subTitle='CD Cover'>
                Sekluzja is the neologizm based on word “secluded” and transformed into polish language. Band is represented by 3 young artists living in Cracow. Jazz noir is a genre that best describes Sekluzja. Jazz improvisation, minimal ambient sounds and musical pauses or vibrations of sounds is all part of this musical experiment. The structure is loose, sometimes unplanned, creating nostalgic, a little heavy and dark vibe.
            </ProjectTopSection>
            <img style={{ marginBottom: '20rem' }} className={styles.pageImage} src={CdCover1} alt="Sekluzja Main" />
            <img style={{ marginBottom: '9rem' }} className={styles.pageImage} src={CdCover2} alt="Sekluzja Secondary" />
            <a className='body' style={{ marginBottom: '12rem', display: 'inline-block' }} href='/'>
                <h4 style={{ fontWeight: 400, textDecoration: 'underline' }}>
                    Listen to <h4 style={{ fontWeight: 600, display: 'inline' }}>Sekluzja</h4>
                </h4>
            </a>
            <PreviousNextProject previousTo='/drawings' previousToTitle='Drawings & Paintings' nextTo='/illustrations' nextToTitle='Illustrations' />
        </div>
    )
}

export default CdCoverDesktop;