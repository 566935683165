import { useEffect } from 'react';
import { breakpoints } from '../../scss/breakpoints';
import IllustrationsDesktop from './IllustrationsDesktop';
import IllustrationsMobile from './IllustrationsMobile';

const Illustrations = () => {

    let isMobile = window.innerWidth < breakpoints.sm;

    useEffect(() => {
        window.addEventListener("resize", () => {
            // console.log('resized', window.innerWidth);
        });
    }, [])

    if (isMobile) {
        return <IllustrationsMobile />
    } else {
        return <IllustrationsDesktop />
    }
}

export default Illustrations;