import { Link } from "react-router-dom";
import compass1 from "../../assets/compass/1.png";
import compass2 from "../../assets/compass/2.gif";
import compass3 from "../../assets/compass/3.png";
import compass4 from "../../assets/compass/4a.gif";
import compass5 from "../../assets/compass/5.png";
import compass6 from "../../assets/compass/6.png";
import compass7 from "../../assets/compass/7.gif";
import compass8 from "../../assets/compass/8.png";
import compass9 from "../../assets/compass/9.gif";
import PreviousNextProject from "../../shared/components/PreviousNextProject/PreviousNextProject";
import styles from "./Compass.module.scss";

const CompassDesktop = () => {
  return (
    <div>
      <img className={styles.pageImage} src={compass1} alt="Compass main" />
      <img className={styles.pageImage} src={compass2} alt="Mobile showcase" />
      <img className={styles.pageImage} src={compass3} alt="Compass" />
      <img className={styles.pageImage} src={compass4} alt="Compass" />
      <img className={styles.pageImage} src={compass5} alt="Compass" />
      <img className={styles.pageImage} src={compass6} alt="Compass" />
      <img className={styles.pageImage} src={compass7} alt="Compass" />
      <img className={styles.pageImage} src={compass8} alt="Compass" />
      <img className={styles.pageImage} src={compass9} alt="Compass" />
      <a
        className="body"
        style={{ marginTop: "12rem", display: "inline-block" }}
        href="https://www.figma.com/file/qBM8IZqzE1cOsv9e4UZhfG/Compass-mobile-web?node-id=0%3A1&t=c89miCGWjPIdO0h2-1"
      >
        <h4 style={{ fontWeight: 400, textDecoration: "underline" }}>
          Project in <h4 style={{ fontWeight: 600, display: "inline" }}>Figma</h4>
        </h4>
      </a>
      <br />
      <Link className="body mt-4" style={{ marginBottom: "12rem", display: "inline-block" }} to="/compass-desktop">
        <h4 style={{ fontWeight: 400, textDecoration: "underline" }}>
          Version for <h4 style={{ fontWeight: 600, display: "inline" }}>desktop</h4>
        </h4>
      </Link>
      <PreviousNextProject
        previousTo="/illustrations"
        previousToTitle="Illustrations"
        nextTo="/compass-desktop"
        nextToTitle="Compass desktop"
      />
    </div>
  );
};

export default CompassDesktop;
