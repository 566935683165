import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <>
            <hr className="mt-4" />
            <div className={styles.wrapper}>
                <h2 className={`${styles.innerHeader} pb-1`}>
                    Let's Connect
                </h2>
                <span className={`${styles.subInnerHeader} pb-1`}>
                    elzbieta.trzmielewska@gmail.com
                </span>
            </div>
        </>
    )
}

export default Footer;